<template>

    <div class="alterPhone">
        <van-nav-bar class="navbar" title="更换手机号" @click-left="leftReturn" fixed left-arrow/>
        <div style=" width: 92%;height: 1.2rem;"></div>
        <!--密码输入框-->
        <div class="inputPassword">
            <!--三目运算符，判断输入是否已输入数值-->
            <div :class="[pwd == '' ? 'grayCircle':'colorCircle']"></div>
            <div class="text">输入密码</div>
            <div class="line">|</div>
            <input :type="pwdtype"  v-model="pwd"  placeholder="请输入密码"  />
            <!--  点击触发密码的显示和隐藏，初始设置为密码不可见-->
            <img v-show="hide" class="eye" src="../../assets/images/hidden.png" @click="showhide()">
            <img   v-show="!hide" class="eye" src="../../assets/images/show.png" @click="showhide()">
        </div>
        <!--手机号输入框-->
        <div class="inputPhone">
            <div :class="[moblieValue == '' ? 'grayCircle':'colorCircle']" ></div>
            <div class="text">手机号</div>
            <div class="line">|</div>
            <input  v-model="moblieValue" @blur="checkMoblie()" type="tel" placeholder="请输入手机号" maxlength="11" />
        </div>
        <!--验证码输入框-->
        <div class="inputCode">
            <!--三目运算符，判断输入是否已输入数值-->
            <div :class="[verificationCode == '' ? 'grayCircle':'colorCircle']"></div>
            <div class="text">验证码</div>
            <div class="line">|</div>
            <input  v-model="verificationCode" type="text" placeholder="请输入验证码" />
            <button class="getCode" v-show="show" :disabled="getCodeDisabled"  @click="getCode()">获取验证码</button>
            <!--点击获取验证码，切换至倒计时画面-->
            <button v-show="!show" disabled class="count">{{count}} S后重发</button>
        </div>
        <!-- 手机验证提示框-->
<!--        <div class="errorDiv">-->
<!--            <div v-show="errorShow"  class="errorInfo">-->
<!--                {{msg}}-->
<!--            </div>-->
<!--        </div>-->

        <!--  如果手机、密码、验证码其中一个没输入，按钮则为灰色-->
        <button :disabled="!moblieValue || !pwd || !verificationCode" :class="[ moblieValue == ''|| verificationCode == '' || pwd == '' ? 'save':'saveing']" v-on:click="mobileChange">保存</button>

    </div>
</template>

<script>
    import {
        NavBar,
        Toast
    } from 'vant';
    import {mobileChange, queryIdPhoto, sendCode} from "../../getData/getData";
    import {globaluserId, responseUtil} from "../../libs/rongxunUtil";
    export default {
        components: {
            [NavBar.name]: NavBar,
            [Toast.name]: Toast
        },
        name: "alterPhone",
        data(){
            return{
                user_id: globaluserId(),
                getCodeDisabled:false,  //获取验证码按钮可点击状态
                moblieValue:"",//手机输入框状态
                verificationCode:"",//验证码输入框状态
                saveShow:true,//不可点击——保存按钮为显示状态
                show: true,//获取验证码按钮为显示状态
                count: '',//倒计时秒数
                timer: null,//倒计时状态
                pwd:"",//密码框状态
                hide:true,//输入框眼睛为闭眼状态
                pwdtype:"password",//密码输入框的文本类型
                errorShow:false, //手机号格式错误初始状态--不显示
                msg:"请输入正确的手机号",
            }
        },
        methods:{
            //返回上一页
            leftReturn() {
                this.$router.go(-1);
            },
            getCode(){
                if(!this.moblieValue){
                    //Toast('请输入正确的手机号')
                    return
                }
                const TIME_COUNT = 60;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
                let that = this
                let initData = {
                    mobile : that.moblieValue
                }
                sendCode(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{

                    })

                })
            },
            showhide(){
                if (this.pwdtype== "password"){
                    this.pwdtype = "text"       //如果点击眼睛，输入框属性为password时，改为text
                    this.hide = false
                }else {
                    this.pwdtype = "password"
                    this.hide = true
                }

            },
            checkMoblie() {
                var re = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                var phone = this.moblieValue;
                if (phone === ""){
                    this.errorShow = false
                }else if (re.test(phone)) {
                    this.errorShow = false
                    // this.disabled = false
                }else if(!this.errorShow){
                    const TIME_COUNT = 1;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                      responseUtil.alertMsg(this,'请输入正确的手机号')
                        this.moblieValue = ''
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.errorShow = false;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }
                }else {
                    responseUtil.alertMsg(this,'请输入正确的手机号')
                    this.moblieValue = ''
                }
            },
            able(){
                if(this.moblieValue !== '' && this.verificationCode !== '' && this.pwd !== ''){
                    this.unable = true
                }

            },
            mobileChange(){
                let _this=this;
                let mobielChangeData={};

                mobielChangeData.user_id = globaluserId()
                mobielChangeData.oldPassword=_this.pwd;
                mobielChangeData.newMobile=_this.moblieValue;
                mobielChangeData.verifyCode=_this.verificationCode;

                console.log(mobielChangeData)

                mobileChange(mobielChangeData).then(function (response) {
                    console.log(response)
                    responseUtil.dealResponse(_this, response, () => {
                        console.log(response.data)
                        if(200==response.status){
                            responseUtil.alertMsg(_this,'更换成功')
                            _this.$router.push('SetPersonalInfo')
                        }else {
                            responseUtil.alertMsg(_this,'更换失败')
                        }
                    })
                }).catch(function (err) {
                      console.log("操作失败")
                })
            }
        }
    }
</script>

<style scoped>
    /*整个页面的父级DIv*/
    .alterPhone{
        width: 100%;
    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }
    /*手机号码输入错误信息提示*/
    .errorDiv{
        float: left;
        margin-left: 7.5%;
        height: 2rem;
        width: 8rem;
        margin-top: 1rem;
    }
    .errorInfo{
        padding-left: 0.15rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin: 0 auto;
        color:white;
        border-radius: 0.1rem;
        width: 3rem;
        background-color:black;
        opacity: 0.55;
        font-size: 12px;

    }
    /*密码输入框样式*/
    .inputPassword{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
        width: 92%;
      margin:15px 15px 0;
        font-size:16px;
        background:white;
        /*margin-top:30px;*/
      height: 50px;
        border-radius: 0.15rem;/*圆角*/
    }
    .inputPassword input {
        /*width: 5.3rem;*/
      flex: auto;
        float: left;
        /*margin-top: 0.485rem;*/
        border: none;
        font-size: 15px;
        vertical-align:middle;
    }
    /*显示和隐藏图标样式*/
    .eye{
        height: 0.25rem;
        width: 0.55rem;
      margin-right: 15px;
        /*margin-top: 0.58rem;*/
    }
    /*手机号输入框样式*/
    .inputPhone{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 92%;
      margin:15px 15px 0;
        font-size:16px;
        background:white;
        /*margin-top:0.4rem;*/
        height: 50px;
        border-radius: 0.15rem;/*圆角*/
    }
    .inputPhone input{
        float: left;
      flex: auto;
        /*margin-top: 0.485rem;*/
        border: none;
        font-size: 15px;
        vertical-align:middle;
    }
    /*验证码输入框样式*/
    .inputCode{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 345px;
      margin:15px 15px 0;
      font-size:16px;
      background:white;
        /*margin-top:0.4rem;*/
      height: 50px;
      border-radius: 0.15rem;/*圆角*/
    }
    .inputCode input{
      /*flex: auto;*/
        width: 3.5rem;
        /*margin-top: 0.485rem;*/
      border: none;
      font-size: 15px;
      vertical-align:middle;
    }
    /*圆点样式，有值点亮，无值灰色*/
    .colorCircle{
        border-radius: 50%;
        width: 0.15rem;
        height: 0.15rem;
        background-color: #ff5809;
        margin-right: 0.2rem;
        margin-left: 0.35rem;
        /*margin-top: 0.64rem;*/
    }
    .grayCircle{
        border-radius: 50%;
        width: 0.15rem;
        height: 0.15rem;
        background-color: #C7C7C7;
        margin-right: 0.2rem;
        margin-left: 0.35rem;
        /*margin-top: 0.64rem;*/
    }
    /*input属性placeholder样式*/
    input::-webkit-input-placeholder{
        color: #D8D8D8;
        font-family: PingFangSC-Regular;
        /*font-size: 15px;*/
        text-align: left;
        /*transform:translateY(2%);!*-50文字居中*!*/
    }

    /*输入框label样式*/
    .text{
      white-space: nowrap;
        font-size: 14.5px;
        float: left;
        /*margin-top: 0.45rem;*/
        font-weight: bold;
        /*height: 0.5rem;*/
    }
    /*竖线样式*/
    .line {
        float: left;
        padding-right: 0.15rem;
        padding-left: 0.22rem;
        color: #D8D8D8;
        font-size: 15.2px;
        /*margin-top: 0.45rem;*/
    }
    /*获取验证码按钮*/
    .getCode{
      white-space: nowrap;
        float: left;
        color:  #FF5D3B;
        border-radius: 0.2rem;
        /*background-color: #f8f8f8;*/
        font-size: 13px;
        height: 0.85rem;
        width: 2.3rem;
        border: 0.02rem solid transparent;
        background-image: linear-gradient(white,white),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
        /*margin-top: 0.265rem;*/
        margin-left: auto;
      margin-right: 15px;
    }
    /*重发验证码时间按钮*/
    .count{
      white-space: nowrap;
        float: left;
        color:  white;
        border-radius: 0.2rem;
        /*background-color: #f8f8f8;*/
        font-size: 13px;
        height: 0.85rem;
        width: 2.3rem;
        border: 0.02rem solid transparent;
        background-color:#222222;
        opacity: 0.2;
        /*margin-top: 0.265rem;*/
        margin-left: auto;
      margin-right: 15px;
    }
    /*保存按钮样式*/
    .save{
        width: 92%;
        height: 1.2rem;
        margin-left: 4%;
        margin-top: 4.5rem;
        background:rgba(184, 184, 184, 0.2);
        border-radius: 0.15rem;/*圆角*/
        border: none;
        color:white;
        font-weight: bold;
        letter-spacing: 0.05rem;
        font-size: 15px;
    }
    .saveing{
        width: 92%;
        height: 1.2rem;
        margin-left: 4%;
        margin-top: 4.5rem;
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;/*渐变色*/
        border-radius: 0.15rem;/*圆角*/
        border: none;
        color:white;
        font-weight: bold;
        letter-spacing: 0.05rem;
        font-size: 15px;
    }
</style>
